import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import { LOGO } from "../../../config/config";


import {
  viewGeneralForm,
  saveGeneralResponse,
} from "../../../config/forms_api_calls";
import FormRenderer from "../../../components/Reseller/formRender_response";
export default class UserForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_token: "",
      form_title: "",
      form_desc: "",
      formAccNo: "",
      previewContent: [],
      form_submitted: "3",
      responseMessage: "",
      submission_status: "",
      allow_submit: 1,
    };
  }

  async componentDidMount() {
    let form_token = this.props.match.params.form_token;
    this.setState({ form_token: form_token });
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });
    const servicesResponce = await viewGeneralForm(form_token);
    console.log("previewForm: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
      this.setState({
        errorMessage: "The form was not found.",
        successMessage: "",
        disabled: false,
        form_submitted: 2
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The form was not found.",
        successMessage: "",
        disabled: false,
        form_submitted: 2
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const form_data = servicesResponce.data.data[0]["form_content"] ? JSON.parse(
        servicesResponce.data.data[0]["form_content"]
      ) : [];
      const groupsData = servicesResponce.data.data[0]["groups"];
      const groups = groupsData ? JSON.parse(groupsData) : [];
      this.setState({
        form_token: form_token,
        formAccNo: servicesResponce.data.data[0]["form_accountno"],
        form_title: servicesResponce.data.data[0]["form_title"],
        form_desc: servicesResponce.data.data[0]["form_desc"],
        allow_submit: servicesResponce.data.data[0]["allow_submit"],
        form_submitted:
          servicesResponce.data.submission_data[0]["is_submitted"],
          submission_status: servicesResponce.data.submission_data[0]["submission_status"],
        form_data: form_data,
        groups: groups,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while previewing the form.",
        successMessage: "",
        disabled: false,
        form_submitted: 2
      });
    }
  }

  handleSubmitForm = (formData) => {
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    //console.log("saveResponse formAccNo: ", this.state.formAccNo);
    //console.log("saveResponse formData: ", formData);
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.formAccNo);
  };

  saveResponse = async (response, formAccNo) => {
    //console.log("saveResponse response: ", response);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const { form_token, form_title, form_desc } = this.state;

    const submitFormReponse = await saveGeneralResponse(
      response,
      formAccNo,
      form_token,
      form_title,
      form_desc
    );
    console.log("saveResponse response: ", submitFormReponse.data);

    if (
      submitFormReponse.data.status === 403 ||
      submitFormReponse.data.errors === "authentication missing" ||
      submitFormReponse.data.errors === "jwt expired"
    ) {
      //auth.clientLogout();
    } else if (submitFormReponse.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There was some error while submitting the form.</div>',
      });
    } else if (submitFormReponse.data.status === 409 && submitFormReponse.data.message === "Both Filer ID and address validation failed.") {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> Both Filer ID and address validation failed.</div>',
      });
    } else if (submitFormReponse.data.status === 409 && submitFormReponse.data.message === "Address validation failed") {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> Address not validated.</div>',
      });
    } else if (submitFormReponse.data.status === 410) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> Invalid Filer ID.</div>',
      });
    } else if (submitFormReponse.data.status === 200) {
      this.setState({
        responseMessage:
          '<div class="alert alert-success alert-icon mb-3"><em class="icon ni ni-check-circle"></em> Your response was submitted succesfully!</div>',
      });
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
    setTimeout(() => {
      this.setState({
        errorMessageSaveResponse: "",
        responseMessage: "",
      });
    }, 2000);
  };

  render() {
    //console.log("this.state.form_submitted: ", this.state.form_submitted);
    return (
      <>
        <div class="card-inner card-inner-lg form_main_container">
          <div class="d-flex justify-content-center mb-2">
            <img className="logo-dark" src={LOGO} alt="logo" />
          </div>
          {this.state.form_submitted === 0 ? (
            <div class="nk-block mt-4">
              <FormRenderer
                formData={this.state.form_data}
                formTitle={this.state.form_title}
                formDescription={this.state.form_desc}
                groups={this.state.groups}
                onSubmit={this.handleSubmitForm}
                allow_submit={this.state.allow_submit}
                responseMessage={this.state.responseMessage}
                // id={this.state.forms[0].id}
                // setShowResponse={(value) => this.setState({ showResponse: value })}
              />
            </div>
          ) : this.state.form_submitted === 1 && this.state.submission_status === 2 ? (
            <div class="nk-block mt-4">
              <FormRenderer
                formData={this.state.form_data}
                formTitle={this.state.form_title}
                formDescription={this.state.form_desc}
                groups={this.state.groups}
                onSubmit={this.handleSubmitForm}
                allow_submit={this.state.allow_submit}
                responseMessage={this.state.responseMessage}
                // id={this.state.forms[0].id}
                // setShowResponse={(value) => this.setState({ showResponse: value })}
              />
            </div>        
          ) : this.state.form_submitted === 1 && this.state.submission_status === 1 ? (
            <div class="nk-block mt-4">
              <div class="nk-block-content nk-error-ld text-center">
                
                <h3 class="nk-error-title">Form Submitted</h3>
                <div class="alert alert-light alert-icon text-dark">
                Your form has been submitted and approved. We have successfully reviewed your information and appreciate the time and effort you took to provide us with the necessary details.
                </div>
                
              </div>
            </div>          
          ) : this.state.form_submitted === 1 && this.state.submission_status === 0 ? (
            <div class="nk-block mt-4">
              <div class="nk-block-content nk-error-ld text-center">
                
                <h3 class="nk-error-title">Form Submitted</h3>
                <div class="alert alert-light alert-icon text-dark">
                Your form has been submitted and is pending approval. Our team will review the details and get back to you shortly if further information is needed. We appreciate your time and effort in providing us with the necessary details.
                </div>
                
              </div>
            </div>          
          ) : this.state.form_submitted === 2 ? (
            <div class="nk-block mt-4">
              <div class="nk-block-content nk-error-ld text-center">
                <h1 class="nk-error-head">404</h1>
                <h3 class="nk-error-title">Form Unavailable</h3>
                <p class="nk-error-text">
                  We are very sorry for inconvenience. It looks like you’re try
                  to access a form that either has been expired or already
                  submitted.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
